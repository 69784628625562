import React, { useEffect, useRef, useState } from 'react';
import '../styles/MediaGrid.css';

import image1 from '../images/portfolio/image1.jpg';
import image10 from '../images/portfolio/image10.jpg';
import image11 from '../images/portfolio/image11.jpg';
import image12 from '../images/portfolio/image12.jpg';
import image13 from '../images/portfolio/image13.jpg';
import image14 from '../images/portfolio/image14.jpg';
import image15 from '../images/portfolio/image15.jpg';
import image16 from '../images/portfolio/image16.jpg';
import image17 from '../images/portfolio/image17.jpg';
import image18 from '../images/portfolio/image18.jpg';
import image2 from '../images/portfolio/image2.jpg';
import image3 from '../images/portfolio/image3.jpg';
import image4 from '../images/portfolio/image4.jpg';
import image5 from '../images/portfolio/image5.jpg';
import image6 from '../images/portfolio/image6.jpg';
import image7 from '../images/portfolio/image7.jpg';
import image8 from '../images/portfolio/image8.jpg';
import image9 from '../images/portfolio/image9.jpg';
import image19 from '../images/portfolio/image19.jpg';
import image20 from '../images/portfolio/image20.jpg';
import image21 from '../images/portfolio/image21.jpg';
import image22 from '../images/portfolio/image22.jpg';

const mediaItems = [
  { type: "image", src: image1, alt: "Image 1" },
  { type: "image", src: image2, alt: "Image 2" },
  { type: "youtube", src: "https://www.youtube.com/embed/mJ2-_QBsqo8", alt: "Video" },
  { type: "image", src: image3, alt: "Image 3" },
  { type: "image", src: image4, alt: "Image 4" },
  { type: "image", src: image5, alt: "Image 5" },
  { type: "youtube", src: "https://www.youtube.com/embed/ICZ-dVHJDcE", alt: "Vid2eo" },
  { type: "image", src: image6, alt: "Image 6" },
  { type: "image", src: image7, alt: "Image 7" },
  { type: "image", src: image22, alt: "Image 22" },
  { type: "youtube", src: "https://www.youtube.com/embed/_ySq2WOrT_E", alt: "YouTube Video" },
  { type: "youtube", src: "https://www.youtube.com/embed/Eo0zk5XT4ZA", alt: "Vid2eo" },
  { type: "image", src: image8, alt: "Image 8" },
  { type: "youtube", src: "https://www.youtube.com/embed/oT29-ynAtE8", alt: "Vid2eo" },
  { type: "youtube", src: "https://www.youtube.com/embed/RMYNxe-pV1s", alt: "Vid2eo" },
  { type: "image", src: image20, alt: "Image 20" },
  { type: "image", src: image9, alt: "Image 9" },
  { type: "image", src: image10, alt: "Image 10" },
  { type: "image", src: image21, alt: "Image 21" },
  { type: "image", src: image11, alt: "Image 11" },
  { type: "youtube", src: "https://youtube.com/embed/KuOoBNtVbYk", alt: "Vid2eo" },
  { type: "image", src: image12, alt: "Image 12" },
  { type: "image", src: image13, alt: "Image 13" },
  { type: "youtube", src: "https://www.youtube.com/embed/odBzJfrlaEs", alt: "Vid2eo" },
  { type: "image", src: image14, alt: "Image 14" },
  { type: "image", src: image15, alt: "Image 15" },
  { type: "image", src: image19, alt: "Image 19" },
  { type: "image", src: image16, alt: "Image 16" },
  { type: "image", src: image18, alt: "Image 18" },
  { type: "image", src: image17, alt: "Image 17" }


];

const MediaGrid = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');
  const [loadedItems, setLoadedItems] = useState({});
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoadedItems((prev) => ({
              ...prev,
              [entry.target.dataset.index]: true
            }));
          }
        });
      },
      { threshold: 0.25 }
    );

    const items = document.querySelectorAll('.media-item');
    items.forEach((item) => observerRef.current.observe(item));

    return () => {
      observerRef.current.disconnect();
    };
  }, []);

  const openLightbox = (videoSrc) => {
    if (window.innerWidth <= 768) {
      setCurrentVideo(videoSrc);
      setLightboxOpen(true);
    }
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setCurrentVideo('');
  };

  return (
    <div>
      <div className="media-grid">
        {mediaItems.map((item, index) => (
          <div className="media-item" key={index} data-index={index}>
            {item.type === "image" ? (
              <img src={item.src} alt={item.alt} loading="lazy" />
            ) : (
              loadedItems[index] && (
                <iframe
                  src={item.src}
                  title={item.alt}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onClick={() => openLightbox(item.src)}
                ></iframe>
              )
            )}
          </div>
        ))}
      </div>

      {lightboxOpen && (
        <div className="lightbox">
          <div className="lightbox-content">
            <iframe
              src={currentVideo}
              title="Lightbox Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <button className="close-lightbox" onClick={closeLightbox}>×</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaGrid;
